<template>
  <v-dialog overlay-opacity="0.6" persistent max-width="1024" :value="value">
    <!-- @input is required to emit emit input if clicked outside unless we use persistent -->

    <messageBox ref="msgBox" />
    <setCurrency ref="setCurrency" />
    <setString ref="setString" />

    <v-container fill-height fluid grid-list-xl>
      <v-layout justify-center wrap>
        <v-flex md12>
          <base-material-card class="pa-0" color="primary">
            <template v-slot:heading>
              <v-row class="pa-0">
                <v-col cols="1" class="pa-0"
                  ><v-icon large>mdi-format-list-text</v-icon></v-col
                >
                <v-col
                  align="center"
                  cols="10"
                  class="pa-0 card-title-text"
                  style="font-size: 25px"
                  >{{
                    editingPriceList
                      ? $t("pricelist-editor-modify")
                      : $t("pricelist-editor-add")
                  }}</v-col
                >
                <v-col cols="1" class="pa-0 text-right"
                  ><v-icon @click="closeDialog" large>mdi-close</v-icon></v-col
                >
              </v-row>
            </template>

            <div class="vertical-spacer" />

            <v-progress-circular
              v-if="fetchingData"
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="primary"
            ></v-progress-circular>

            <ValidationObserver
              ref="obs"
              v-slot="{ invalid, validated }"
              v-if="!fetchingData"
            >
              <v-row dense>
                <v-col>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      key="name"
                      v-model="pricelist.name"
                      :label="$t('pricelist-editor-hint-name')"
                      :error-messages="errors"
                      :success="valid"
                      outlined
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row dense align="start">
                <v-col>
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-select
                      :label="$t('pricelist-editor-hint-master')"
                      item-text="name"
                      item-value="id"
                      :error-messages="errors"
                      :success="valid"
                      outlined
                      dense
                      v-model="pricelist.master_price_list"
                      :items="master_lists"
                      :disabled="pricelist.childs_count > 0"
                    />
                  </ValidationProvider>
                </v-col>

                <v-col>
                  <ValidationProvider
                    v-if="!pricelist.limit_products_views"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      :disabled="pricelist.master_price_list > 0"
                      key="global_percent_discount"
                      v-model="pricelist.global_percent_discount"
                      :label="$t('pricelist-editor-hint-discount')"
                      :error-messages="errors"
                      :success="valid"
                      outlined
                      dense
                      type="number"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col> </v-col><v-col> </v-col>
              </v-row>
              <v-card>
                <v-toolbar height="40" color="info" dark>
                  <v-col cols="1"> </v-col
                  ><v-col cols="10">{{
                    $t("pricelist-editor-title-products")
                  }}</v-col>
                  <v-col cols="1"> </v-col>
                </v-toolbar>

                <v-container fluid>
                  <v-row dense>
                    <v-col>
                      <v-autocomplete
                        :disabled="fetchingProducts"
                        v-model="selected_product"
                        :items="products"
                        :loading="loading_products"
                        :search-input.sync="loading_products_search"
                        hide-details
                        outlined
                        dense
                        item-text="compositeName"
                        item-value="id"
                        :placeholder="$t('order-edit-search-product')"
                        prepend-icon="mdi-database-search"
                        return-object
                      >
                        <template v-slot:item="data">
                          <template v-if="isNotObject(data.item)">
                            <v-list-item-content
                              v-text="data.item"
                            ></v-list-item-content>
                          </template>
                          <template v-else>
                            <v-row>
                              <v-col cols="1">
                                <v-progress-circular
                                  v-if="data.item.fetchingImage"
                                  :key="data.item.fetchKey"
                                  :indeterminate="true"
                                  :rotate="0"
                                  :size="48"
                                  :width="4"
                                  color="primary"
                                ></v-progress-circular>
                                <v-list-item-avatar
                                  style="margin-top:10px; border:1px solid black;"
                                  v-if="!data.item.fetchingImage"
                                  tile
                                  size="48"
                                  :key="data.item.fetchKey"
                                >
                                  <img
                                    v-if="data.item.fetchedImage"
                                    :src="data.item.fetchedImage"
                                  />
                                  <img
                                    v-if="!data.item.fetchedImage"
                                    :src="require('../assets/product.png')"
                                  />
                                </v-list-item-avatar>
                              </v-col>
                              <v-col align="left" cols="11">
                                <v-list-item-content>
                                  <v-list-item-title
                                    style="line-height:48px"
                                    v-html="data.item.compositeName"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-col>
                            </v-row>
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row v-if="fetchingProducts">
                    <v-col col="12">
                      <v-progress-circular
                        :indeterminate="true"
                        :rotate="0"
                        :size="32"
                        :width="4"
                        color="primary"
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                  <v-row v-if="!fetchingProducts">
                    <v-col col="12">
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th>{{ $t("pricelist-editor-tbl-code") }}</th>
                            <th class="text-left">
                              {{ $t("pricelist-editor-tbl-name") }}
                            </th>
                            <th class="text-left">
                              {{ $t("pricelist-editor-tbl-product-prefix") }}
                            </th>
                            <th class="text-center">
                              {{ $t("pricelist-editor-tbl-measure-unit") }}
                            </th>

                            <th class="text-center">
                              {{ $t("pricelist-editor-tbl-list-price") }}
                            </th>
                            <th class="text-center">
                              {{ $t("pricelist-editor-tbl-discount") }}
                            </th>

                            <th class="text-center">
                              {{ $t("pricelist-editor-tbl-discount-shown") }}
                            </th>
                            <th class="text-center"></th>
                          </tr>
                        </thead>
                        <tbody :key="table_key">
                          <tr
                            v-for="(item, index) in pricelist.products"
                            :key="index"
                          >
                            <td class="text-center">
                              {{ getProductCode(item) }}
                            </td>

                            <td class="text-left">
                              {{ item.name }}
                            </td>

                            <td class="text-left">
                              {{ item.product_prefix }}
                              <v-tooltip bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="primary"
                                    @click.stop="editPrefix(item)"
                                    v-on="on"
                                    ><v-icon>mdi-pencil</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("order-edit-set-price-title")
                                }}</span></v-tooltip
                              >
                            </td>

                            <td class="text-center">
                              {{ getMeasureUnit(item) }}
                            </td>

                            <td style="min-width:150px" class="text-center">
                              {{
                                $options.filters.toCurrencySymbol(
                                  item.price_per_unit
                                )
                              }}
                              <v-tooltip bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="primary"
                                    @click.stop="editPrice(item)"
                                    v-on="on"
                                    ><v-icon>mdi-pencil</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("order-edit-set-price-title")
                                }}</span></v-tooltip
                              >

                              <v-tooltip bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="orange"
                                    @click.stop="
                                      setDiscountFromCurrentPrice(item)
                                    "
                                    v-on="on"
                                    ><v-icon>mdi-sack-percent</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("order-edit-set-discount-from-value-title")
                                }}</span></v-tooltip
                              >
                              <v-tooltip bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="purple"
                                    @click.stop="setDiscount(item)"
                                    v-on="on"
                                    ><v-icon>mdi-sack-percent</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("order-edit-set-discount-title")
                                }}</span></v-tooltip
                              >
                            </td>

                            <td style="min-width:150px" class="text-center">
                              {{
                                $options.filters.toCurrency(
                                  (
                                    100 -
                                    (item.price_per_unit / item.base_price) *
                                      100
                                  ).toFixed(2)
                                )
                              }}
                              %
                            </td>
                            <td style="min-width:80px" class="text-center">
                              <v-text-field
                                outlined
                                hide-details
                                dense
                                v-model="item.discount_shown"
                                @keypress="isDiscountNumber($event)"
                              >
                              </v-text-field>
                            </td>

                            <td class="text-center">
                              <v-tooltip bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="error"
                                    @click.stop="deleteProduct(item)"
                                    v-on="on"
                                    ><v-icon
                                      >mdi-delete-circle-outline</v-icon
                                    ></v-btn
                                  ></template
                                ><span>{{
                                  $t("pricelist-item-action-delete")
                                }}</span></v-tooltip
                              >
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>

              <v-card>
                <v-toolbar height="40" color="info" dark>
                  <v-col cols="1"> </v-col
                  ><v-col cols="10">{{
                    $t("pricelist-editor-title-categories")
                  }}</v-col>
                  <v-col cols="1"> </v-col>
                </v-toolbar>

                <v-container fluid>
                  <v-row dense>
                    <v-col>
                      <v-autocomplete
                        :disabled="fetchingCategories"
                        v-model="selected_category"
                        :items="categories"
                        :loading="loading_categories"
                        :search-input.sync="loading_categories_search"
                        hide-details
                        outlined
                        dense
                        item-text="name"
                        item-value="id"
                        :placeholder="$t('order-edit-search-category')"
                        prepend-icon="mdi-database-search"
                        return-object
                      >
                        <template v-slot:item="data">
                          <template v-if="isNotObject(data.item)">
                            <v-list-item-content
                              v-text="data.item"
                            ></v-list-item-content>
                          </template>
                          <template v-else>
                            <v-row>
                              <v-col align="left">
                                <v-list-item-content>
                                  <v-list-item-title
                                    style="line-height:24px"
                                    v-html="data.item.name"
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-col>
                            </v-row>
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>

                  <v-row v-if="fetchingCategories">
                    <v-col col="12">
                      <v-progress-circular
                        :indeterminate="true"
                        :rotate="0"
                        :size="32"
                        :width="4"
                        color="primary"
                      ></v-progress-circular>
                    </v-col>
                  </v-row>
                  <v-row v-if="!fetchingCategories">
                    <v-col col="12">
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t("pricelist-editor-tbl-category-name") }}
                            </th>
                            <th class="text-center">
                              {{ $t("pricelist-editor-tbl-discount-amount") }}
                            </th>
                            <th class="text-center"></th>
                          </tr>
                        </thead>
                        <tbody :key="table_key">
                          <tr
                            v-for="(item, index) in pricelist.categories"
                            :key="index"
                          >
                            <td class="text-left">
                              {{ item.name }}
                            </td>

                            <td class="text-center">
                              {{
                                $options.filters.toCurrency(
                                  item.price_per_unit
                                ) + "%"
                              }}<v-btn
                                @click="editCategoryPrice(item)"
                                class="ml-4"
                                fab
                                dark
                                width="24"
                                height="24"
                                color="primary"
                                ><v-icon small dark>mdi-pencil</v-icon></v-btn
                              >
                            </td>
                            <td class="text-center">
                              <v-tooltip bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="error"
                                    @click.stop="deleteCategory(item)"
                                    v-on="on"
                                    ><v-icon
                                      >mdi-delete-circle-outline</v-icon
                                    ></v-btn
                                  ></template
                                ><span>{{
                                  $t("pricelist-item-action-delete-category")
                                }}</span></v-tooltip
                              ><v-tooltip bottom
                                ><template v-slot:activator="{ on }"
                                  ><v-btn
                                    x-small
                                    text
                                    icon
                                    color="secondary"
                                    @click.stop="insertCategoryProducts(item)"
                                    v-on="on"
                                    ><v-icon>mdi-arrow-up</v-icon></v-btn
                                  ></template
                                ><span>{{
                                  $t("pricelist-item-action-insert-category")
                                }}</span></v-tooltip
                              >
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>

              <div style="height:10px" />

              <v-btn
                width="120"
                :disabled="invalid || fetchingProducts"
                @click="confirm()"
                color="primary"
                >{{ $t("gbl-ok") }}</v-btn
              >
              <v-btn
                :disabled="fetchingProducts"
                width="120"
                @click="cancel()"
                color="secondary"
                >{{ $t("gbl-cancel") }}</v-btn
              >
            </ValidationObserver>
          </base-material-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<style scoped>
.card-title-text {
  font-size: 1.5em;
}
</style>

<script>
import messageBox from "../components/MessageBox";
import setCurrency from "../components/SetCurrency";
import setString from "../components/SetString";
import productsManager from "../apis/products";
import priceListsManager from "../apis/pricelists";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import config from "../config";

export default {
  data() {
    return {
      pricelist: {
        categories: [],
        products: []
      },
      pricelist_types: [],
      editingPriceList: false,

      fetchingProducts: false,
      fetchingCategories: false,
      fetchingData: true,

      master_lists: [],
      table_key: 0,

      products: [],
      selected_product: null,
      loading_products: false,
      loading_products_search: null,
      imageCache: {},

      categories: [],
      selected_category: null,
      loading_categories: false,
      loading_categories_search: null
    };
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    messageBox,
    setCurrency,
    setString
  },

  props: ["value"],

  mounted() {
    this.fetchingData = true;
    priceListsManager
      .fetchTypes()
      .then(res => {
        this.pricelist_types = res.map(x => {
          x.name = this.$t(x.name);
          return x;
        });
        this.fetchingData = false;
      })
      .catch(err => {
        console.log(err);
        this.fetchingData = false;
      });
  },

  watch: {
    selected_product: {
      handler() {
        if (this.selected_product) {
          this.addProduct(this.selected_product);
          this.selected_product = null;
          this.products = [];
          this.loading_products_search = null;
          this.loading_products = false;
        }
      }
    },

    selected_category: {
      handler() {
        if (this.selected_category) {
          this.addCategory(this.selected_category);
          this.selected_category = null;
          this.categories = [];
          this.loading_categories_search = null;
          this.loading_categories = false;
        }
      }
    },

    loading_products_search: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (
            !this.loading_products &&
            this.loading_products_search &&
            this.loading_products_search.length
          ) {
            this.loading_products = true;
            let ctx = {};
            ctx.currentPage = 1;
            ctx.perPage = null;
            ctx.filter = this.loading_products_search;
            ctx.sortBy = "name";
            ctx.sortDesc = true;
            productsManager
              .fetchProducts(ctx)
              .then(result => {
                this.loading_products = false;
                this.products = [];
                for (let index in result.items) {
                  if (!this.isInPriceList(result.items[index])) {
                    result.items[index].fetchingImage = true;
                    result.items[index].fetchKey = 0;
                    this.fetchProductImage(result.items[index]);
                    let id = result.items[index].id.toString();
                    while (id.length < config.productIdPadding) id = "0" + id;
                    result.items[index].compositeName =
                      id + " - " + result.items[index].name;
                    this.products.push(result.items[index]);
                  }
                }
              })
              .catch(err => {
                console.log(err);
                this.loading_products = false;
              });
          } else if (
            !this.loading_products_search ||
            !this.loading_products_search.length
          ) {
            this.selected_product = null;
            this.products = [];
          }
        }, 300);
      }
    },
    loading_categories_search: {
      handler() {
        clearTimeout(this._debounceTimer);
        this._debounceTimer = setTimeout(() => {
          if (
            !this.loading_categories &&
            this.loading_categories_search &&
            this.loading_categories_search.length
          ) {
            this.loading_categories = true;
            let ctx = {};
            ctx.currentPage = 1;
            ctx.perPage = null;
            ctx.filter = this.loading_categories_search;
            ctx.sortBy = "name";
            ctx.sortDesc = true;
            productsManager
              .fetchCategories(ctx)
              .then(result => {
                this.loading_categories = false;
                this.categories = [];
                for (let index in result.items) {
                  if (!this.isInCategoryList(result.items[index])) {
                    this.categories.push(result.items[index]);
                  }
                }
              })
              .catch(err => {
                console.log(err);
                this.loading_categories = false;
              });
          } else if (
            !this.loading_categories_search ||
            !this.loading_categories_search.length
          ) {
            this.selected_category = null;
            this.categories = [];
          }
        }, 300);
      }
    }
  },

  methods: {
    getMeasureUnit(item) {
      if (item && item.measure_unit) {
        return this.$t(
          this.$store.state.measure_units_by_id[item.measure_unit]
        );
      }
      return "";
    },

    isDiscountNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode !== 44
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    getProductCode(item) {
      if (item && item.id) {
        let padding = item.id.toString();
        while (padding.length < config.productIdPadding)
          padding = "0" + padding;
        return padding;
      }
      return "";
    },

    fetchProducts: function(id) {
      return new Promise((resolve, reject) => {
        priceListsManager
          .fetchProducts(id)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    fetchCategories: function(id) {
      return new Promise((resolve, reject) => {
        priceListsManager
          .fetchCategories(id)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    isInPriceList(item) {
      for (let index in this.pricelist.products) {
        if (this.pricelist.products[index].id == item.id) return true;
      }
      return false;
    },

    isInCategoryList(item) {
      for (let index in this.pricelist.categories) {
        if (this.pricelist.categories[index].id == item.id) return true;
      }
      return false;
    },

    isNotObject(item) {
      return typeof item !== "object";
    },

    fetchProductImage(item) {
      let that = this;
      return new Promise(function(resolve, reject) {
        if (item.fetchingImage) {
          if (!that.imageCache[item.id]) {
            productsManager
              .fetchProductImage(item.id)
              .then(results => {
                if (results.image) {
                  item.fetchedImage = "data:image/png;base64," + results.image;
                  that.imageCache[item.id] = results.image;
                  item.fetchKey++;
                }
                item.fetchingImage = false;
                resolve();
              })
              .catch(() => {});
          } else {
            item.fetchedImage =
              "data:image/png;base64," + that.imageCache[item.id];
            item.fetchingImage = false;
            resolve();
          }
        } else {
          reject();
        }
      });
    },

    addProduct: function(product) {
      let p = {
        id: product.id,
        price_per_unit: product.price,
        base_price: product.price,
        name: product.name,
        measure_unit: product.measure_unit,
        product_prefix: "",
        discount_shown: 0
      };
      this.pricelist.products.push(p);
    },

    addCategory: function(category) {
      let c = {
        id: category.id,
        price_per_unit: 0,
        name: category.name
      };
      this.pricelist.categories.push(c);
    },

    editPrefix(item) {
      this.$refs.setString
        .show(
          this.$t("order-edit-set-prefix-text"),
          this.$t("order-edit-set-prefix-hint"),
          this.$t("order-edit-set-prefix-title"),
          item.product_prefix,
          item,
          false,
          false
        )
        .then(event => {
          item.product_prefix = event.value;
          this.table_key++;
        })
        .catch(() => {});
    },

    editPrice(item) {
      this.$refs.setCurrency
        .show(
          this.$t("order-edit-set-price-text"),
          this.$t("order-edit-set-price-hint"),
          this.$t("order-edit-set-price-title"),
          item.price_per_unit.toString().replace(".", ","),
          item,
          true,
          false
        )
        .then(event => {
          item.price_per_unit = parseFloat(
            event.value.replace(",", ".")
          ).toFixed(2);
          this.table_key++;
        })
        .catch(() => {});
    },

    setDiscountFromCurrentPrice(item) {
      this.$refs.setCurrency
        .show(
          this.$t("order-edit-set-discount-text"),
          this.$t("order-edit-set-discount-hint"),
          this.$t("order-edit-set-discount-title"),
          0,
          item,
          true,
          false
        )
        .then(event => {
          item.discount_shown = parseFloat(event.value).toFixed(2);

          item.price_per_unit = (
            item.price_per_unit -
            (item.price_per_unit * event.value) / 100
          ).toFixed(2);
          this.table_key++;
        })
        .catch(() => {});
    },

    setDiscount(item) {
      this.$refs.setCurrency
        .show(
          this.$t("order-edit-set-discount-text"),
          this.$t("order-edit-set-discount-hint"),
          this.$t("order-edit-set-discount-title"),
          0,
          item,
          true,
          false
        )
        .then(event => {
          item.discount_shown = parseFloat(event.value).toFixed(2);

          item.price_per_unit = (
            item.base_price -
            (item.base_price * event.value) / 100
          ).toFixed(2);
          this.table_key++;
        })
        .catch(() => {});
    },

    editCategoryPrice(item) {
      this.$refs.setCurrency
        .show(
          this.$t("pricelist-editor-set-discount-text"),
          this.$t("pricelist-editor-set-discount-hint"),
          this.$t("pricelist-editor-set-discount-title"),
          this.$options.filters.toCurrency(item.price_per_unit),
          item,
          true,
          false
        )
        .then(event => {
          item.price_per_unit = parseFloat(event.value.replace(",", "."));
          this.table_key++;
        })
        .catch(() => {});
    },

    deleteProduct: function(item) {
      this.$refs.msgBox
        .show(
          this.$t("pricelist-editor-product-delete-confirm"),
          this.$t("pricelist-editor-product-delete-title")
        )
        .then(() => {
          const index = this.pricelist.products.indexOf(item);
          if (index > -1) {
            this.pricelist.products.splice(index, 1);
            this.table_key++;
          }
        })
        .catch(() => {});
    },

    deleteCategory: function(item) {
      this.$refs.msgBox
        .show(
          this.$t("pricelist-editor-category-delete-confirm"),
          this.$t("pricelist-editor-category-delete-title")
        )
        .then(() => {
          const index = this.pricelist.categories.indexOf(item);
          if (index > -1) {
            this.pricelist.categories.splice(index, 1);
            this.table_key++;
          }
        })
        .catch(() => {});
    },

    insertCategoryProducts: function(item) {
      this.$refs.msgBox
        .show(
          this.$t("pricelist-editor-category-insert-products-confirm"),
          this.$t("pricelist-editor-category-insert-products-title")
        )
        .then(() => {
          const index = this.pricelist.categories.indexOf(item);
          if (index > -1) {
            this.pricelist.categories.splice(index, 1);
            this.table_key++;
          }

          let ctx = {};
          ctx.currentPage = 1;
          ctx.perPage = null;
          ctx.filter = this.loading_products_search;
          ctx.sortBy = "name";
          ctx.sortDesc = true;
          ctx.filterByProductCategory = item.id;
          productsManager
            .fetchProducts(ctx)
            .then(result => {
              this.loading_products = false;
              this.products = [];
              for (let index in result.items) {
                if (!this.isInPriceList(result.items[index])) {
                  result.items[index].fetchingImage = true;
                  result.items[index].fetchKey = 0;
                  this.fetchProductImage(result.items[index]);
                  let id = result.items[index].id.toString();
                  while (id.length < config.productIdPadding) id = "0" + id;
                  result.items[index].compositeName =
                    id + " - " + result.items[index].name;

                  let product = result.items[index];
                  let p = {
                    id: product.id,
                    price_per_unit: product.price,
                    base_price: product.price,
                    name: product.name,
                    measure_unit: product.measure_unit,
                    product_prefix: "",
                    discount_shown: 0
                  };
                  this.pricelist.products.push(p);

                  //this.products.push(result.items[index]);
                }
              }
            })
            .catch(err => {
              console.log(err);
              this.loading_products = false;
            });
        })
        .catch(() => {});
    },

    confirm: function() {
      for (let x in this.pricelist.products) {
        let product = this.pricelist.products[x];
        if (product.discount_shown) {
          product.discount_shown = parseFloat(
            product.discount_shown.toString().replace(",", ".")
          ).toFixed(2);
        }
      }
      this.$emit("pricelistConfigured", {
        editingPriceList: this.editingPriceList,
        pricelist: this.pricelist
      });
      this.$refs.obs.reset();
    },

    closeDialog: function() {
      this.cancel();
    },

    cancel: function() {
      this.$emit("input"); // Input signal emitted from a dialog closes it, not clear in the documentation
    },

    resetModal() {
      this.pricelist.global_percent_discount = 0.0;
      this.pricelist.name = "";
      this.pricelist.products = [];
      this.pricelist.categories = [];
      this.pricelist.master_price_list = 0;
      this.pricelist.childs_count = 0;
    },

    setItem(editing, pricelist) {
      this.resetModal();
      this.editingPriceList = editing;
      this.fetchingProducts = true;
      this.fetchingCategories = true;
      this.fetchingData = true;

      priceListsManager
        .fetchMasterLists()
        .then(res => {
          if (editing) {
            res = res.filter(x => {
              if (x.id == pricelist.id) return false;
              else return true;
            });
          }

          this.master_lists = [
            { id: 0, name: this.$t("gbl-none-male") },
            ...res
          ];

          this.fetchingData = false;
          if (editing) {
            this.pricelist = Object.assign({}, pricelist);
            this.pricelist.categories = [];
            this.pricelist.products = [];
            this.fetchProducts(pricelist.id)
              .then(res => {
                this.pricelist.products = res;
                this.fetchingProducts = false;
                this.fetchCategories(pricelist.id)
                  .then(res => {
                    this.pricelist.categories = res;
                    this.fetchingCategories = false;
                  })
                  .catch(err => {
                    this.fetchingCategories = false;
                    console.log(err);
                  });
              })
              .catch(err => {
                this.fetchingProducts = false;
                console.log(err);
              });
            requestAnimationFrame(() => {
              if (this.$refs.obs) {
                this.$refs.obs.validate();
              }
            });
          } else {
            this.fetchingProducts = false;
            this.fetchingCategories = false;
            this.pricelist = {
              id: null,
              type: 0,
              name: "",
              products: [],
              categories: [],
              global_percent_discount: 0,
              master_price_list: 0
            };
            requestAnimationFrame(() => {
              if (this.$refs.obs) this.$refs.obs.reset();
            });
          }
        })
        .catch(err => {
          console.log(err);
          this.fetchingData = false;
        });
    }
  }
};
</script>
