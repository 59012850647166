var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('messageBox',{ref:"msgBox"}),_c('CustomerEditor',{ref:"customerEditor",on:{"customerConfigured":_vm.handleCustomer},model:{value:(_vm.customerEditorDialog),callback:function ($$v) {_vm.customerEditorDialog=$$v},expression:"customerEditorDialog"}}),_c('customersCategoryEditor',{ref:"categoryEditor",on:{"categoryEditingDone":_vm.handleCategoryEditingDone},model:{value:(_vm.categoryEditorDialog),callback:function ($$v) {_vm.categoryEditorDialog=$$v},expression:"categoryEditorDialog"}}),_c('v-card',{staticClass:"ma-4"},[_c('v-toolbar',{attrs:{"color":"primary"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white","large":""}},[_vm._v("mdi-account-group")]),_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.$t("customers-title")))]),_c('v-spacer')],1),_c('div',[_c('div',{staticStyle:{"height":"10px"}})]),_c('v-row',{staticClass:"text-center"},[_c('v-col',[_c('v-btn',{attrs:{"disabled":_vm.fetchingData,"color":"info"},on:{"click":function($event){$event.stopPropagation();return _vm.addCustomer()}}},[_vm._v(_vm._s(_vm.$t("customers-add-customer")))]),_c('v-btn',{attrs:{"disabled":_vm.fetchingData,"color":"primary"},on:{"click":function($event){return _vm.manageCategory(0)}}},[_vm._v(_vm._s(_vm.$t("customers-mng-category")))]),_c('v-menu',{attrs:{"rounded":"lg","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.fetchingData,"color":"success"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-file-excel-outline")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t("customers-mng-export-excel")))])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.exportExcel(false)}}},[_vm._v(_vm._s(_vm.$t("customers-mng-export-list")))])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.exportExcel(true, true)}}},[_vm._v(_vm._s(_vm.$t("customers-mng-export-contacts-primary")))])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.exportExcel(true, false)}}},[_vm._v(_vm._s(_vm.$t("customers-mng-export-contacts-total")))])],1)],1)],1),_c('v-menu',{attrs:{"rounded":"lg","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.fetchingData,"color":"success"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-file-table-outline")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t("customers-mng-export-csv")))])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.exportCSV(false)}}},[_vm._v(_vm._s(_vm.$t("customers-mng-export-list")))])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.exportCSV(true, true)}}},[_vm._v(_vm._s(_vm.$t("customers-mng-export-contacts-primary")))])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.exportCSV(true, false)}}},[_vm._v(_vm._s(_vm.$t("customers-mng-export-contacts-total")))])],1)],1)],1)],1)],1),_c('div',{staticStyle:{"margin":"15px"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.fetchingData,"clearable":"","dense":"","no-filter":"","items":_vm.flt_customers,"loading":_vm.isLoadingCustomer,"search-input":_vm.searchCustomerInput,"outlined":"","item-text":"display_name","item-value":"id","label":_vm.$t('customers-filter-customer'),"return-object":""},on:{"update:searchInput":function($event){_vm.searchCustomerInput=$event},"update:search-input":function($event){_vm.searchCustomerInput=$event}},model:{value:(_vm.flt_customer),callback:function ($$v) {_vm.flt_customer=$$v},expression:"flt_customer"}})],1)],1)],1),_c('v-data-table',{attrs:{"dense":"","items-per-page":_vm.perPage,"must-sort":true,"headers":_vm.headers,"items":_vm.customers,"options":_vm.options,"server-items-length":_vm.totalCustomers,"loading":_vm.fetchingData,"footer-props":_vm.dataTableFooterOptions,"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.customer_color",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.reference_color}},[_vm._v("mdi-circle")])]}},{key:"item.last_order_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toLocaleDate")(item.last_order_date))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"min-width":"120px"}},[(
              item.email &&
                item.customer_login_id &&
                _vm.$store.state.hasRight('237')
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"#23aae1"},on:{"click":function($event){$event.stopPropagation();return _vm.resetCredentials(item)}}},on),[_c('v-icon',[_vm._v("mdi-account-reactivate-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("customers-reset-external-credentials")))])]):_vm._e(),(item.customer_login_id && _vm.$store.state.hasRight('239'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"#92278f"},on:{"click":function($event){$event.stopPropagation();return _vm.generatePasspartout(item)}}},on),[_c('v-icon',[_vm._v("mdi-account-key-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("customers-generate-passpartout")))])]):_vm._e(),(!item.enabled && _vm.$store.state.hasRight('156'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.banCustomer(item, false)}}},on),[_c('v-icon',[_vm._v("mdi-account-off")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("customers-unban")))])]):_vm._e(),(item.enabled && _vm.$store.state.hasRight('156'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.banCustomer(item, true)}}},on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("customers-ban")))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"gray"},on:{"click":function($event){$event.stopPropagation();return _vm.editCustomer(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("customer-action-edit")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","text":"","icon":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteCustomer(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("customer-action-delete")))])])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }